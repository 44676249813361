import BasePlugin from './BasePlugin';

export default class KompetenzFloatingActionButtonPlugin extends BasePlugin {

  shouldRun() {
    return $('body').hasClass('node-type-kompetenz') || $('body').hasClass('node-type-seite');
  }

  init() {
    let $popup = $('.page-floating').find('.field-name-field-ansprechpartner');

    if ($popup.length == 0) {
      $('.page-floating').remove();
    }

    $popup.prepend('<i class="fa fa-close"></i>');

    if ($(window).outerWidth() > 500) {
      $popup.addClass('visible');
    }
  }

  addEventListeners() {
    $('.fab-toggle').click(this.toggleFab);
    $('.page-floating').find('.field-name-field-ansprechpartner i').click(this.closeFab);
  }

  toggleFab(event) {
    event.preventDefault();
    $('.page-floating').find('.field-name-field-ansprechpartner').toggleClass('visible');
  }

  closeFab(event) {
    event.preventDefault();
    $('.page-floating').find('.field-name-field-ansprechpartner').removeClass('visible');
  }
}
