import BasePlugin from './BasePlugin';

export default class KompetenzViewPlugin extends BasePlugin {

  shouldRun() {
      return $('body').hasClass('page-kompetenzen');
  }


  init() {
      $('.view-kompetenzen .views-row').each(function() {
        let url = $(this).find('.field-name-node-link a').attr('href');
        $(this).find('.img-title-wrapper').wrapInner(`<a href="${url}"></a>`);
      })
  }
}
