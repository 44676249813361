import BasePlugin from './BasePlugin';

export default class ParagraphsPlugin extends BasePlugin {

  shouldRun() {
    return $('.entity-paragraphs-item').length > 0;
  }

  addEventListeners() {
    this.addEventListenersTeaser();
    this.addEventListenersOeffnungszeiten();
  }

  init() {
    this.initTeaser();
    this.initOeffnungszeiten();
  }

  // Teaser Paragraph

  initTeaser() {
    $('.paragraphs-item-teaser').each(function(){
      let url = $(this).find('.field-name-custom-teaser-linked-title a').attr('href');

      if (url) {
        $(this).find('.field-name-field-bild img').wrap(`<a href="${url}"></a>`);
      }
    });
  }

  addEventListenersTeaser() {

  }

  // Oeffnungszeiten Paragraph

  initOeffnungszeiten() {
    // Add "Uhr"
    $('.oh-display-times').each(function(index, el){
      let text = ($(this)).text();
      let textArray = text.split(', ');

      let newTextArray = [];
      textArray.forEach(function(text){
        newTextArray.push(text + ' Uhr');
      });

      text = newTextArray.join(', ');
      $(this).html(text + '<br>');
    });
  }

  addEventListenersOeffnungszeiten() {

  }
}
