import BasePlugin from './BasePlugin';

/**
 *
 */
export default class ReferenzPlugin extends BasePlugin {

  shouldRun() {
    return $('body').hasClass('node-type-referenz');
  }


  init() {
    this.removeEmptySidebarFields();
  }

  removeEmptySidebarFields() {
    // Linked Logo
    let $linkedLogo = $('.region-sidebar .field-name-custom-linked-logo');
    if ($linkedLogo.find('a').html().trim() == "") {
      $linkedLogo.remove();
    }

    // Kundenmeinung
    if ($('.field-name-field-kundenmeinung-name').length == 0) {
      $('.field-name-custom-referenz-label-kunde').remove();
    }
  }
}
