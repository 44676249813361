import BasePlugin from './BasePlugin';
import Toolbox from '../lib/Toolbox';

export default class AngebotPlugin extends BasePlugin {

  constructor() {
    super();
    this.selectedProducts = [];
  }

  shouldRun() {
    return $('body').hasClass('node-type-angebot');
  }

  addEventListeners() {
    let self = this;

    $('.node-angebotsprodukt .field-name-custom-node-angebot-anfrage-butt a').click(function(e) {
      e.preventDefault();
      let name = $(this).parents('.node-angebotsprodukt').find('.field-name-title h2').text();
      self.checkItem(name, true);
      Toolbox.slideTo($('.field-name-custom-block-angebotsanfrageform'));
    });

    $('.angebotsprodukte-select .produkt').click(function() {
      let name = $(this).data('name');
      self.checkItem(name);
    });
  }

  init() {
    this.addGueltigkeitToHeader();
    this.addCheckboxesToForm();
    this.hideWhenOnlyOneProduct();
    this.addPreisAufAnfrage();
    this.showPreviewImageIfMoreThanOneProduct();
  }

  checkItem(name, forceOn = false) {
    let index = this.selectedProducts.indexOf(name);

    if (index == -1) {
      this.selectedProducts.push(name);
    } else {
      if (!forceOn) {
        this.selectedProducts.splice(index, 1);
      }
    }

    this.writeDataToTextfield();
    this.checkSelectedProducts();
  }

  addCheckboxesToForm() {
    $('.webform-component--interesse-an input').before('<div class="angebotsprodukte-select"></div>');
    let $angebote = $('.node-angebotsprodukt .field-name-title');
    $angebote.each(function() {
      $('.angebotsprodukte-select').append('<div class="produkt" data-name="' + $(this).text() + '"><i class="fa fa-square-o"></i> ' + $(this).text() + '</div>')
    });

    if ($angebote.length == 1) {
      let name = $('.angebotsprodukte-select .produkt').data('name');
      this.checkItem(name);
    }
  }

  addGueltigkeitToHeader() {
    let $endDateDiv = $('.node-angebot .field-name-custom-node-angebot-gueltig-bis');
    let endDate = $endDateDiv.text();
    if (endDate.length > 0) {
      $('h1#page-title span').append(' Gültig bis ' + endDate);
    }

    $endDateDiv.remove();
  }

  checkSelectedProducts() {
    $('.angebotsprodukte-select .produkt i').removeClass('fa-check-square-o').addClass('fa-square-o');

    this.selectedProducts.forEach(function(product, index){
      $('.angebotsprodukte-select .produkt[data-name="'+ product +'"] i').removeClass('fa-square-o').addClass('fa-check-square-o');
    })
  }

  writeDataToTextfield() {
    let value = this.selectedProducts.join(', ');
    $('.webform-component--interesse-an input').val(value);
  }

  hideWhenOnlyOneProduct() {
    if ($('.node-angebotsprodukt').length == 1) {
      let itemName = $('.webform-component--interesse-an .produkt:first-child').data('name');

      this.checkItem(itemName, true);

      $('.webform-component--interesse-an').addClass('element-hidden');
    }
  }

  addPreisAufAnfrage() {
    $('.node-angebotsprodukt').each(function() {
      if ($(this).find('.field-name-field-preis').length == 0) {
        $(this).find('.field-name-custom-node-angebot-anfrage-butt').before('<div class="field field-name-field-preis">Preis auf Anfrage</div>');
      }
    })
  }

  showPreviewImageIfMoreThanOneProduct() {
    if ($('.node-angebotsprodukt').length > 1) {
      $('.node-angebot .group-top .field-name-custom-code-angebot-non-cropped-').show();
    }
  }
}
