import BasePlugin from "./BasePlugin";
import Toolbox from "../lib/Toolbox";

export default class FrontpagePlugin extends BasePlugin {
  shouldRun() {
    return $('body').hasClass('front');
  }


  init() {
    this.fullWidthBoxes();
    this.linkKompetenzBox();
    this.angeboteMoreButton();
  }

  fullWidthBoxes() {
    let $kompetenzenBox = $('#block-views-kompetenzen-block-1');
    Toolbox.syncHeight($kompetenzenBox.find('> .content'), $kompetenzenBox, function () {
      let documentWidth = $(window).outerWidth();
      return documentWidth > 752;
    });

    let $angeboteBox = $('#block-views-angebote-block-1');
    Toolbox.syncHeight($angeboteBox.find('.view-content'), $angeboteBox, function () {
      return true;
    }, function (height) { return height + $angeboteBox.find('.view-header').outerHeight(); });
  }

  linkKompetenzBox() {
    $('.view-kompetenzen .views-row').each(function () {
      let url = $(this).find('.views-field-nid a').attr('href');

      if (url) {
        $(this).find('.blue-wrapper').wrap(`<a href="${url}"></a>`);
      }
    });
  }

  angeboteMoreButton() {
    $('.view-angebote .more-link').appendTo('.view-angebote .view-content');
  }
}
