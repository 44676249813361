import './plugins/TablesPlugin';

import PushyPlugin from './plugins/PushyPlugin';
import TerminAnmeldungSelectBoxPlugin from './plugins/TerminAnmeldungSelectBoxPlugin';
import TerminAnmeldungButtonPlugin from './plugins/TerminAnmeldungButtonPlugin';
import KompetenzFloatingActionButtonPlugin from './plugins/KompetenzFloatingActionButtonPlugin';
import KompetenzSlideToEintrag from './plugins/KompetenzSlideToEintrag';
import AngebotPlugin from './plugins/AngebotPlugin';
import AnsprechpartnerFilterPlugin from './plugins/AnsprechpartnerFilterPlugin';
import FrontpagePlugin from './plugins/FrontpagePlugin';
import BlogeintragPlugin from './plugins/BlogeintragPlugin';
import ParagraphsPlugin from './plugins/ParagraphsPlugin';
import AngebotsUebersichtsPlugin from './plugins/AngebotsUebersichtsPlugin';
import KompetenzViewPlugin from './plugins/KompetenzViewPlugin';
import FooterPlugin from './plugins/FooterPlugin';
import ReferenzPlugin from './plugins/ReferenzPlugin';
import KompetenzEmptyFieldPlugin from './plugins/KompetenzEmptyFieldPlugin';
import ShareButtonPlugin from './plugins/ShareButtonPlugin';
import MailchimpSignupBlockPlugin from './plugins/MailchimpSignupBlockPlugin';
import ProduktuebersichtPlugin from './plugins/ProduktuebersichtPlugin';
import MerkzettelPlugin from './plugins/MerkzettelPlugin';


$ = jQuery;

jQuery(document).ready(function($) {
  // - Replace links with Grasenhiller Logo
  var ghLogo = '<img height="25" width="140" style="max-width: 140px !important;" src="'+ Drupal.settings.basePath +'sites/all/themes/gh_custom/dist/images/grasenhiller.png" alt="Grasenhiller - Webdesign in Neumarkt, Amberg, Nürnberg & Weiden" />'
  $('a.grasenhiller-logo').html(ghLogo);

  // - Match height
  // $('.row').matchHeight({
  //   byRow: true,
  //   property: 'height',
  //   target: null,
  //   remove: false
  // });

  new PushyPlugin().run();
  new TerminAnmeldungSelectBoxPlugin().run();
  new TerminAnmeldungButtonPlugin().run();
  new KompetenzFloatingActionButtonPlugin().run();
  new KompetenzSlideToEintrag().run();
  new AngebotPlugin().run();
  new AnsprechpartnerFilterPlugin().run();
  new FrontpagePlugin().run();
  new BlogeintragPlugin().run();
  new ParagraphsPlugin().run();
  new AngebotsUebersichtsPlugin().run();
  new KompetenzViewPlugin().run();
  new FooterPlugin().run();
  new ReferenzPlugin().run();
  new KompetenzEmptyFieldPlugin().run();
  new ShareButtonPlugin().run();
  new MailchimpSignupBlockPlugin().run();
  new ProduktuebersichtPlugin().run();
  new MerkzettelPlugin().run();

  // - Readmore for products
  $('.node-produkt').each(function() {
    var content = $(this).find('.field-name-body');

    if (content[0]) {
      var height = content.height(),
        realHeight = content[0].scrollHeight;

      if (realHeight > height) {
        content.addClass('more');
        $(this).find('.group-right').append('<a href="#" class="more-button-product">mehr Erfahren</a>');
      }
    }
  });

  $('body').on('click', '.more-button-product', function(e) {
    e.preventDefault();

    var content = $(this).prev('.field-name-body');
    if(content.hasClass('show-more')) {
      content.removeClass('show-more');
      $(this).text('mehr Erfahren');
    } else {
      content.addClass('show-more');
      $(this).text('schließen');
    }
  });

  $('#node-23391 .group-left .field-name-field-bild').appendTo('#node-23391 .group-left .field-name-field-bild-link a');
});

jQuery.fn.slideFadeToggle = function(speed, easing, callback) {
  return this.animate({opacity: 'toggle', height: 'toggle'}, speed, easing, callback);
};
