export default class Merkzettel {
  constructor () {
    this.items = [];
    this.infoShown = false;
    this.initialized = false;
    this.supported = false;
    this.minimized =  false;
    this.settings = {
      buttonLink: '/produktanfrage',
      parameterName: 'products',
      buttonLabel: 'Anfrage absenden'
    };
  }

  init () {
    // Check if localstorage is supported
    if (typeof(Storage) !== "undefined") {
      this.supported = true;
      this.loadSavedData();
      if (this.items.length > 0) {
        this.render();
      }
    } else {
      this.supported = false;
    }

    this.initialized = true;
  }

  /**
   * Adds an item to the merkzettel
   * @param passedItem An object containing data about the item. Required: id.
   */
  addItem(passedItem) {
    let existing = false;

    this.items.forEach(function(item, index) {
      if (item.id == passedItem.id) {
        existing = true;
      }
    });

    if (!existing) {
      this.items.push(passedItem);
      this.render();

      if (!this.infoShown) {
        this.showInfo();
      }

      this.saveData();
    }
  }

  removeItem(index) {
    this.items.splice(index, 1);
    this.render();
    this.saveData();
  }

  showInfo() {
    let self = this;

    this.infoShown = true;

    let html = `
      <div class="merkzettelInfo">
        <div class="content">
          <div class="text">
            <h1>Produkt wurde zum Merkzettel hinzugefügt</h1>
            <p>Sie haben jetzt die Möglichkeit weitere Produkte zu Ihrem Merkzettel hinzuzufügen und dann eine gesammelte Anfrage zu stellen.</p>
            <div class="button-wrapper">
              <a class="button">Ok, verstanden</a>
            </div>
          </div>
          <i class="fa fa-arrow-right"></i>
        </div>
      </div>
      `;

    $('body').append(html);

    $('.merkzettelInfo a.button').click(function (e) {
      e.preventDefault();
      self.hideInfo();
    });

    if (jQuery(document).width() < 400) {
      this.minimize();
    }
  }

  hideInfo() {
    $('.merkzettelInfo').remove();
  }

  getItemHTML(item, index) {
    return `<li data-index="${index}"><i class="fa fa-close"></i>${item.title}</li>`;
  }

  getItemSummary(item) {
    return '- ' + item.title + "\n";
  }

  render() {
    let self = this;
    let itemString = '';
    let itemHTML = '';
    let minimizedClass = '';
    let html;


    // Remove existing
    $('.merkzettel').remove();

    // Do not show when no items present
    if (this.items.length == 0) {
      return;
    }

    if (this.minimized) {
      minimizedClass = ' minimized';
    }

    // Generate itemString and itemHTML
    this.items.forEach(function(item, index) {
      itemString += self.getItemSummary(item, index);
      itemHTML += self.getItemHTML(item, index);
    });

    // Encode itemString for button url
    itemString = encodeURIComponent(itemString);

    // Generate HTML
    html = `
      <div class="merkzettel${minimizedClass}">
        <h3><i class="fa fa-chevron-down"></i>Merkzettel<span class="count"> (${this.items.length})</span></h3>
        <ul>
          ${itemHTML}
        </ul>
        <a class="button" href="${this.settings.buttonLink}?${this.settings.parameterName}=${itemString}">${this.settings.buttonLabel}</a>
      </div>`;


    // Insert the merzettel into the page
    $('body').append(html);

    // Add event listeners
    // Remove Button
    $('.merkzettel li i').click(function () {
      let $parent = jQuery(this).parents('li');
      let index = $parent.data('index');
      self.removeItem(index);
    });

    // Minimize button
    $('.merkzettel h3').click(function () {
      if ($(this).parents('.merkzettel').hasClass('minimized')) {
        self.maximize();
      } else {
        self.minimize();
      }
    });
  }

  minimize() {
    $('.merkzettel').addClass('minimized');
    this.minimized = true;
    localStorage.setItem('merkzettelMinimized', 'true');
  }

  maximize() {
    $('.merkzettel').removeClass('minimized');
    this.minimized = false;
    localStorage.setItem('merkzettelMinimized', 'false');
  }

  loadSavedData() {
    this.items = JSON.parse(localStorage.getItem('merkzettelItems'));
    this.minimized = localStorage.getItem('merkzettelMinimized') == 'true';

    if (!this.items) {
      this.items = [];
    }
  }

  saveData() {
    localStorage.setItem('merkzettelItems', JSON.stringify(this.items));
  }
}
