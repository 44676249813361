(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.main = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _Merkzettel2 = require('./Merkzettel');

var _Merkzettel3 = _interopRequireDefault(_Merkzettel2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FichtnerMerkzettel = function (_Merkzettel) {
  _inherits(FichtnerMerkzettel, _Merkzettel);

  function FichtnerMerkzettel() {
    _classCallCheck(this, FichtnerMerkzettel);

    var _this = _possibleConstructorReturn(this, (FichtnerMerkzettel.__proto__ || Object.getPrototypeOf(FichtnerMerkzettel)).call(this));

    _this.settings.buttonLabel = 'Zum Anfrageformular';
    _this.typeLabels = {
      information: "Information",
      angebot: "Angebot"
    };
    return _this;
  }

  _createClass(FichtnerMerkzettel, [{
    key: 'getItemHTML',
    value: function getItemHTML(item, index) {
      var anzahl = "";

      if (item.typ == 'angebot') {
        anzahl = ' (Menge: ' + item.menge + ')';
      }

      return '<li data-index="' + index + '">\n      <i class="fa fa-close"></i>\n      <div class="title">' + item.title + '</div>\n      <span class="sub">' + this.typeLabels[item.typ] + anzahl + '</span>      \n    </li>';
    }
  }, {
    key: 'getItemSummary',
    value: function getItemSummary(item) {
      var nummern = [];

      if (item.artikelnummer != "") {
        nummern.push(item.artikelnummer);
      }

      if (item.herstellernummer != "") {
        nummern.push(item.herstellernummer);
      }

      if (item.eannummer != "") {
        nummern.push(item.eannummer);
      }

      nummern = nummern.join(' / ');
      nummern = ' [' + nummern + ']';

      var anzahl = "";

      if (item.typ == 'angebot') {
        anzahl = ' [Menge: ' + item.menge + ']';
      }

      return '- [' + this.typeLabels[item.typ] + ']' + nummern + ' ' + item.title + anzahl + "\n";
    }
  }]);

  return FichtnerMerkzettel;
}(_Merkzettel3.default);

exports.default = FichtnerMerkzettel;

},{"./Merkzettel":2}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Merkzettel = function () {
  function Merkzettel() {
    _classCallCheck(this, Merkzettel);

    this.items = [];
    this.infoShown = false;
    this.initialized = false;
    this.supported = false;
    this.minimized = false;
    this.settings = {
      buttonLink: '/produktanfrage',
      parameterName: 'products',
      buttonLabel: 'Anfrage absenden'
    };
  }

  _createClass(Merkzettel, [{
    key: 'init',
    value: function init() {
      // Check if localstorage is supported
      if (typeof Storage !== "undefined") {
        this.supported = true;
        this.loadSavedData();
        if (this.items.length > 0) {
          this.render();
        }
      } else {
        this.supported = false;
      }

      this.initialized = true;
    }

    /**
     * Adds an item to the merkzettel
     * @param passedItem An object containing data about the item. Required: id.
     */

  }, {
    key: 'addItem',
    value: function addItem(passedItem) {
      var existing = false;

      this.items.forEach(function (item, index) {
        if (item.id == passedItem.id) {
          existing = true;
        }
      });

      if (!existing) {
        this.items.push(passedItem);
        this.render();

        if (!this.infoShown) {
          this.showInfo();
        }

        this.saveData();
      }
    }
  }, {
    key: 'removeItem',
    value: function removeItem(index) {
      this.items.splice(index, 1);
      this.render();
      this.saveData();
    }
  }, {
    key: 'showInfo',
    value: function showInfo() {
      var self = this;

      this.infoShown = true;

      var html = '\n      <div class="merkzettelInfo">\n        <div class="content">\n          <div class="text">\n            <h1>Produkt wurde zum Merkzettel hinzugef\xFCgt</h1>\n            <p>Sie haben jetzt die M\xF6glichkeit weitere Produkte zu Ihrem Merkzettel hinzuzuf\xFCgen und dann eine gesammelte Anfrage zu stellen.</p>\n            <div class="button-wrapper">\n              <a class="button">Ok, verstanden</a>\n            </div>\n          </div>\n          <i class="fa fa-arrow-right"></i>\n        </div>\n      </div>\n      ';

      $('body').append(html);

      $('.merkzettelInfo a.button').click(function (e) {
        e.preventDefault();
        self.hideInfo();
      });

      if (jQuery(document).width() < 400) {
        this.minimize();
      }
    }
  }, {
    key: 'hideInfo',
    value: function hideInfo() {
      $('.merkzettelInfo').remove();
    }
  }, {
    key: 'getItemHTML',
    value: function getItemHTML(item, index) {
      return '<li data-index="' + index + '"><i class="fa fa-close"></i>' + item.title + '</li>';
    }
  }, {
    key: 'getItemSummary',
    value: function getItemSummary(item) {
      return '- ' + item.title + "\n";
    }
  }, {
    key: 'render',
    value: function render() {
      var self = this;
      var itemString = '';
      var itemHTML = '';
      var minimizedClass = '';
      var html = void 0;

      // Remove existing
      $('.merkzettel').remove();

      // Do not show when no items present
      if (this.items.length == 0) {
        return;
      }

      if (this.minimized) {
        minimizedClass = ' minimized';
      }

      // Generate itemString and itemHTML
      this.items.forEach(function (item, index) {
        itemString += self.getItemSummary(item, index);
        itemHTML += self.getItemHTML(item, index);
      });

      // Encode itemString for button url
      itemString = encodeURIComponent(itemString);

      // Generate HTML
      html = '\n      <div class="merkzettel' + minimizedClass + '">\n        <h3><i class="fa fa-chevron-down"></i>Merkzettel<span class="count"> (' + this.items.length + ')</span></h3>\n        <ul>\n          ' + itemHTML + '\n        </ul>\n        <a class="button" href="' + this.settings.buttonLink + '?' + this.settings.parameterName + '=' + itemString + '">' + this.settings.buttonLabel + '</a>\n      </div>';

      // Insert the merzettel into the page
      $('body').append(html);

      // Add event listeners
      // Remove Button
      $('.merkzettel li i').click(function () {
        var $parent = jQuery(this).parents('li');
        var index = $parent.data('index');
        self.removeItem(index);
      });

      // Minimize button
      $('.merkzettel h3').click(function () {
        if ($(this).parents('.merkzettel').hasClass('minimized')) {
          self.maximize();
        } else {
          self.minimize();
        }
      });
    }
  }, {
    key: 'minimize',
    value: function minimize() {
      $('.merkzettel').addClass('minimized');
      this.minimized = true;
      localStorage.setItem('merkzettelMinimized', 'true');
    }
  }, {
    key: 'maximize',
    value: function maximize() {
      $('.merkzettel').removeClass('minimized');
      this.minimized = false;
      localStorage.setItem('merkzettelMinimized', 'false');
    }
  }, {
    key: 'loadSavedData',
    value: function loadSavedData() {
      this.items = JSON.parse(localStorage.getItem('merkzettelItems'));
      this.minimized = localStorage.getItem('merkzettelMinimized') == 'true';

      if (!this.items) {
        this.items = [];
      }
    }
  }, {
    key: 'saveData',
    value: function saveData() {
      localStorage.setItem('merkzettelItems', JSON.stringify(this.items));
    }
  }]);

  return Merkzettel;
}();

exports.default = Merkzettel;

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Toolbox = function () {
  function Toolbox() {
    _classCallCheck(this, Toolbox);
  }

  _createClass(Toolbox, null, [{
    key: 'selectFieldPlaceholderClass',

    /**
     * Automatically adds a class to the specified element, if no value is selected
     * @param {jQuery} selectField - The selectfield where the placeholder class should be added to.
     * @param {string} [emptyValue=] - The value which should be treated as empty
     * @param {string} [placeholderClass=placeholder] - The class which will be added
     */
    value: function selectFieldPlaceholderClass(selectField) {
      var emptyValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var placeholderClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'placeholder';

      var doCheck = function doCheck() {
        if (selectField.val() == emptyValue) {
          selectField.addClass(placeholderClass);
        } else {
          selectField.removeClass(placeholderClass);
        }
      };

      selectField.change(doCheck);
      doCheck();
    }

    /**
     * Animates a scroll to a specific element
     * @param {jQuery} target - Where to scroll to
     */

  }, {
    key: 'slideTo',
    value: function slideTo(target) {
      $('html, body').animate({
        scrollTop: target.offset().top
      });
    }

    /***
     * Returns a query parameter
     * @param {string} name - The key of the GET Param
     * @param {string} [url=window.location.href] - The url from which the param shouuld be taken from
     * @returns {string}
     */

  }, {
    key: 'getParameterByName',
    value: function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      url = url.toLowerCase(); // This is just to avoid case sensitiveness
      name = name.replace(/[\[\]]/g, "\\$&").toLowerCase(); // This is just to avoid case sensitiveness for query parameter name
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
  }, {
    key: 'syncHeight',
    value: function syncHeight($source, $target) {
      var shouldRun = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {
        return true;
      };
      var manipulateHeight = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (height) {
        return height;
      };


      var resize = function resize() {
        if (shouldRun()) {
          var height = $source.outerHeight();
          $target.height(manipulateHeight(height));
        } else {
          $target.height('auto');
        }
      };

      $(window).resize(resize);

      window.setTimeout(resize, 500);
      resize();
    }
  }]);

  return Toolbox;
}();

exports.default = Toolbox;

},{}],4:[function(require,module,exports){
'use strict';

require('./plugins/TablesPlugin');

var _PushyPlugin = require('./plugins/PushyPlugin');

var _PushyPlugin2 = _interopRequireDefault(_PushyPlugin);

var _TerminAnmeldungSelectBoxPlugin = require('./plugins/TerminAnmeldungSelectBoxPlugin');

var _TerminAnmeldungSelectBoxPlugin2 = _interopRequireDefault(_TerminAnmeldungSelectBoxPlugin);

var _TerminAnmeldungButtonPlugin = require('./plugins/TerminAnmeldungButtonPlugin');

var _TerminAnmeldungButtonPlugin2 = _interopRequireDefault(_TerminAnmeldungButtonPlugin);

var _KompetenzFloatingActionButtonPlugin = require('./plugins/KompetenzFloatingActionButtonPlugin');

var _KompetenzFloatingActionButtonPlugin2 = _interopRequireDefault(_KompetenzFloatingActionButtonPlugin);

var _KompetenzSlideToEintrag = require('./plugins/KompetenzSlideToEintrag');

var _KompetenzSlideToEintrag2 = _interopRequireDefault(_KompetenzSlideToEintrag);

var _AngebotPlugin = require('./plugins/AngebotPlugin');

var _AngebotPlugin2 = _interopRequireDefault(_AngebotPlugin);

var _AnsprechpartnerFilterPlugin = require('./plugins/AnsprechpartnerFilterPlugin');

var _AnsprechpartnerFilterPlugin2 = _interopRequireDefault(_AnsprechpartnerFilterPlugin);

var _FrontpagePlugin = require('./plugins/FrontpagePlugin');

var _FrontpagePlugin2 = _interopRequireDefault(_FrontpagePlugin);

var _BlogeintragPlugin = require('./plugins/BlogeintragPlugin');

var _BlogeintragPlugin2 = _interopRequireDefault(_BlogeintragPlugin);

var _ParagraphsPlugin = require('./plugins/ParagraphsPlugin');

var _ParagraphsPlugin2 = _interopRequireDefault(_ParagraphsPlugin);

var _AngebotsUebersichtsPlugin = require('./plugins/AngebotsUebersichtsPlugin');

var _AngebotsUebersichtsPlugin2 = _interopRequireDefault(_AngebotsUebersichtsPlugin);

var _KompetenzViewPlugin = require('./plugins/KompetenzViewPlugin');

var _KompetenzViewPlugin2 = _interopRequireDefault(_KompetenzViewPlugin);

var _FooterPlugin = require('./plugins/FooterPlugin');

var _FooterPlugin2 = _interopRequireDefault(_FooterPlugin);

var _ReferenzPlugin = require('./plugins/ReferenzPlugin');

var _ReferenzPlugin2 = _interopRequireDefault(_ReferenzPlugin);

var _KompetenzEmptyFieldPlugin = require('./plugins/KompetenzEmptyFieldPlugin');

var _KompetenzEmptyFieldPlugin2 = _interopRequireDefault(_KompetenzEmptyFieldPlugin);

var _ShareButtonPlugin = require('./plugins/ShareButtonPlugin');

var _ShareButtonPlugin2 = _interopRequireDefault(_ShareButtonPlugin);

var _MailchimpSignupBlockPlugin = require('./plugins/MailchimpSignupBlockPlugin');

var _MailchimpSignupBlockPlugin2 = _interopRequireDefault(_MailchimpSignupBlockPlugin);

var _ProduktuebersichtPlugin = require('./plugins/ProduktuebersichtPlugin');

var _ProduktuebersichtPlugin2 = _interopRequireDefault(_ProduktuebersichtPlugin);

var _MerkzettelPlugin = require('./plugins/MerkzettelPlugin');

var _MerkzettelPlugin2 = _interopRequireDefault(_MerkzettelPlugin);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

$ = jQuery;

jQuery(document).ready(function ($) {
  // - Replace links with Grasenhiller Logo
  var ghLogo = '<img height="25" width="140" style="max-width: 140px !important;" src="' + Drupal.settings.basePath + 'sites/all/themes/gh_custom/dist/images/grasenhiller.png" alt="Grasenhiller - Webdesign in Neumarkt, Amberg, Nürnberg & Weiden" />';
  $('a.grasenhiller-logo').html(ghLogo);

  // - Match height
  // $('.row').matchHeight({
  //   byRow: true,
  //   property: 'height',
  //   target: null,
  //   remove: false
  // });

  new _PushyPlugin2.default().run();
  new _TerminAnmeldungSelectBoxPlugin2.default().run();
  new _TerminAnmeldungButtonPlugin2.default().run();
  new _KompetenzFloatingActionButtonPlugin2.default().run();
  new _KompetenzSlideToEintrag2.default().run();
  new _AngebotPlugin2.default().run();
  new _AnsprechpartnerFilterPlugin2.default().run();
  new _FrontpagePlugin2.default().run();
  new _BlogeintragPlugin2.default().run();
  new _ParagraphsPlugin2.default().run();
  new _AngebotsUebersichtsPlugin2.default().run();
  new _KompetenzViewPlugin2.default().run();
  new _FooterPlugin2.default().run();
  new _ReferenzPlugin2.default().run();
  new _KompetenzEmptyFieldPlugin2.default().run();
  new _ShareButtonPlugin2.default().run();
  new _MailchimpSignupBlockPlugin2.default().run();
  new _ProduktuebersichtPlugin2.default().run();
  new _MerkzettelPlugin2.default().run();

  // - Readmore for products
  $('.node-produkt').each(function () {
    var content = $(this).find('.field-name-body');

    if (content[0]) {
      var height = content.height(),
          realHeight = content[0].scrollHeight;

      if (realHeight > height) {
        content.addClass('more');
        $(this).find('.group-right').append('<a href="#" class="more-button-product">mehr Erfahren</a>');
      }
    }
  });

  $('body').on('click', '.more-button-product', function (e) {
    e.preventDefault();

    var content = $(this).prev('.field-name-body');
    if (content.hasClass('show-more')) {
      content.removeClass('show-more');
      $(this).text('mehr Erfahren');
    } else {
      content.addClass('show-more');
      $(this).text('schließen');
    }
  });

  $('#node-23391 .group-left .field-name-field-bild').appendTo('#node-23391 .group-left .field-name-field-bild-link a');
});

jQuery.fn.slideFadeToggle = function (speed, easing, callback) {
  return this.animate({ opacity: 'toggle', height: 'toggle' }, speed, easing, callback);
};

},{"./plugins/AngebotPlugin":5,"./plugins/AngebotsUebersichtsPlugin":6,"./plugins/AnsprechpartnerFilterPlugin":7,"./plugins/BlogeintragPlugin":9,"./plugins/FooterPlugin":10,"./plugins/FrontpagePlugin":11,"./plugins/KompetenzEmptyFieldPlugin":12,"./plugins/KompetenzFloatingActionButtonPlugin":13,"./plugins/KompetenzSlideToEintrag":14,"./plugins/KompetenzViewPlugin":15,"./plugins/MailchimpSignupBlockPlugin":16,"./plugins/MerkzettelPlugin":17,"./plugins/ParagraphsPlugin":18,"./plugins/ProduktuebersichtPlugin":19,"./plugins/PushyPlugin":20,"./plugins/ReferenzPlugin":21,"./plugins/ShareButtonPlugin":22,"./plugins/TablesPlugin":23,"./plugins/TerminAnmeldungButtonPlugin":24,"./plugins/TerminAnmeldungSelectBoxPlugin":25}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _Toolbox = require('../lib/Toolbox');

var _Toolbox2 = _interopRequireDefault(_Toolbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AngebotPlugin = function (_BasePlugin) {
  _inherits(AngebotPlugin, _BasePlugin);

  function AngebotPlugin() {
    _classCallCheck(this, AngebotPlugin);

    var _this = _possibleConstructorReturn(this, (AngebotPlugin.__proto__ || Object.getPrototypeOf(AngebotPlugin)).call(this));

    _this.selectedProducts = [];
    return _this;
  }

  _createClass(AngebotPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-angebot');
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      var self = this;

      $('.node-angebotsprodukt .field-name-custom-node-angebot-anfrage-butt a').click(function (e) {
        e.preventDefault();
        var name = $(this).parents('.node-angebotsprodukt').find('.field-name-title h2').text();
        self.checkItem(name, true);
        _Toolbox2.default.slideTo($('.field-name-custom-block-angebotsanfrageform'));
      });

      $('.angebotsprodukte-select .produkt').click(function () {
        var name = $(this).data('name');
        self.checkItem(name);
      });
    }
  }, {
    key: 'init',
    value: function init() {
      this.addGueltigkeitToHeader();
      this.addCheckboxesToForm();
      this.hideWhenOnlyOneProduct();
      this.addPreisAufAnfrage();
      this.showPreviewImageIfMoreThanOneProduct();
    }
  }, {
    key: 'checkItem',
    value: function checkItem(name) {
      var forceOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var index = this.selectedProducts.indexOf(name);

      if (index == -1) {
        this.selectedProducts.push(name);
      } else {
        if (!forceOn) {
          this.selectedProducts.splice(index, 1);
        }
      }

      this.writeDataToTextfield();
      this.checkSelectedProducts();
    }
  }, {
    key: 'addCheckboxesToForm',
    value: function addCheckboxesToForm() {
      $('.webform-component--interesse-an input').before('<div class="angebotsprodukte-select"></div>');
      var $angebote = $('.node-angebotsprodukt .field-name-title');
      $angebote.each(function () {
        $('.angebotsprodukte-select').append('<div class="produkt" data-name="' + $(this).text() + '"><i class="fa fa-square-o"></i> ' + $(this).text() + '</div>');
      });

      if ($angebote.length == 1) {
        var name = $('.angebotsprodukte-select .produkt').data('name');
        this.checkItem(name);
      }
    }
  }, {
    key: 'addGueltigkeitToHeader',
    value: function addGueltigkeitToHeader() {
      var $endDateDiv = $('.node-angebot .field-name-custom-node-angebot-gueltig-bis');
      var endDate = $endDateDiv.text();
      if (endDate.length > 0) {
        $('h1#page-title span').append(' Gültig bis ' + endDate);
      }

      $endDateDiv.remove();
    }
  }, {
    key: 'checkSelectedProducts',
    value: function checkSelectedProducts() {
      $('.angebotsprodukte-select .produkt i').removeClass('fa-check-square-o').addClass('fa-square-o');

      this.selectedProducts.forEach(function (product, index) {
        $('.angebotsprodukte-select .produkt[data-name="' + product + '"] i').removeClass('fa-square-o').addClass('fa-check-square-o');
      });
    }
  }, {
    key: 'writeDataToTextfield',
    value: function writeDataToTextfield() {
      var value = this.selectedProducts.join(', ');
      $('.webform-component--interesse-an input').val(value);
    }
  }, {
    key: 'hideWhenOnlyOneProduct',
    value: function hideWhenOnlyOneProduct() {
      if ($('.node-angebotsprodukt').length == 1) {
        var itemName = $('.webform-component--interesse-an .produkt:first-child').data('name');

        this.checkItem(itemName, true);

        $('.webform-component--interesse-an').addClass('element-hidden');
      }
    }
  }, {
    key: 'addPreisAufAnfrage',
    value: function addPreisAufAnfrage() {
      $('.node-angebotsprodukt').each(function () {
        if ($(this).find('.field-name-field-preis').length == 0) {
          $(this).find('.field-name-custom-node-angebot-anfrage-butt').before('<div class="field field-name-field-preis">Preis auf Anfrage</div>');
        }
      });
    }
  }, {
    key: 'showPreviewImageIfMoreThanOneProduct',
    value: function showPreviewImageIfMoreThanOneProduct() {
      if ($('.node-angebotsprodukt').length > 1) {
        $('.node-angebot .group-top .field-name-custom-code-angebot-non-cropped-').show();
      }
    }
  }]);

  return AngebotPlugin;
}(_BasePlugin3.default);

exports.default = AngebotPlugin;

},{"../lib/Toolbox":3,"./BasePlugin":8}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _Toolbox = require('../lib/Toolbox');

var _Toolbox2 = _interopRequireDefault(_Toolbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AngebotsUebersichtsPlugin = function (_BasePlugin) {
    _inherits(AngebotsUebersichtsPlugin, _BasePlugin);

    function AngebotsUebersichtsPlugin() {
        _classCallCheck(this, AngebotsUebersichtsPlugin);

        return _possibleConstructorReturn(this, (AngebotsUebersichtsPlugin.__proto__ || Object.getPrototypeOf(AngebotsUebersichtsPlugin)).apply(this, arguments));
    }

    _createClass(AngebotsUebersichtsPlugin, [{
        key: 'shouldRun',
        value: function shouldRun() {
            return $('body').hasClass('page-angebote');
        }
    }, {
        key: 'init',
        value: function init() {
            var $angeboteBox = $('.view-angebote');
            _Toolbox2.default.syncHeight($angeboteBox.find('> .view-content'), $angeboteBox, function () {
                return true;
            });
        }
    }]);

    return AngebotsUebersichtsPlugin;
}(_BasePlugin3.default);

exports.default = AngebotsUebersichtsPlugin;

},{"../lib/Toolbox":3,"./BasePlugin":8}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _Toolbox = require('../lib/Toolbox');

var _Toolbox2 = _interopRequireDefault(_Toolbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AnsprechpartnerFilterPlugin = function (_BasePlugin) {
  _inherits(AnsprechpartnerFilterPlugin, _BasePlugin);

  function AnsprechpartnerFilterPlugin() {
    _classCallCheck(this, AnsprechpartnerFilterPlugin);

    return _possibleConstructorReturn(this, (AnsprechpartnerFilterPlugin.__proto__ || Object.getPrototypeOf(AnsprechpartnerFilterPlugin)).apply(this, arguments));
  }

  _createClass(AnsprechpartnerFilterPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('page-unternehmen-ansprechpartner');
    }
  }, {
    key: 'init',
    value: function init() {
      this.byUrlSelected = false;

      this.createFilterMarkup();
      this.insertParentCategories();
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      var self = this;

      $('.gh-filter .cat1').change(function () {
        var value = $(this).val();

        if (value === 'placeholder') {
          $('.gh-filter .cat2').html('<option class="placeholder" value="placeholder">Bitte wählen Sie</option>').val('placeholder').change().attr('disabled', true);

          if (location.search.indexOf('group') !== -1) {
            location.href = location.pathname;
          }
        } else {
          $('.gh-filter .cat2').attr('disabled', false);
          self.loadChildCategories(value);
        }
      });

      $('.gh-filter .cat2').change(function () {
        var value = $(this).val();
        var parent = $('.gh-filter .cat1').val();
        var group = $(this).find('option:selected').data('group');

        if (value !== "placeholder") {
          if (location.search.indexOf('group') === -1 || location.search.indexOf('group') !== -1 && self.byUrlSelected) {
            location.href = location.pathname + ('?group=' + group + '&parent=' + parent + '&value=' + value);
          }
        }
      });
    }
  }, {
    key: 'createFilterMarkup',
    value: function createFilterMarkup() {
      $('.view-filters .views-exposed-widgets').prepend('\n            <div class="gh-filter-widget">\n                <label>Finden Sie den passenden Ansprechpartner f\xFCr</label>\n                \n                <div class="gh-filter">\n                    <select class="cat1">\n                        <option class="placeholder" value="placeholder">Laden...</option>\n                    </select>\n                    <select class="cat2" disabled>\n                        <option class="placeholder" value="placeholder">Bitte w\xE4hlen Sie</option>\n                    </select>\n                </div>\n            </div>\n        ');

      // Toolbox.selectFieldPlaceholderClass($('.gh-filter .cat1'), 'placeholder');
      // Toolbox.selectFieldPlaceholderClass($('.gh-filter .cat2'), 'placeholder');
    }
  }, {
    key: 'insertParentCategories',
    value: function insertParentCategories() {
      var self = this;

      this.loadDataFromApi(0).success(function (data) {
        var cats = JSON.parse(data);

        var html = '<option value="placeholder">Bitte wählen Sie</option>';
        html += self.renderOptions(cats);

        $('.gh-filter .cat1').html(html);

        self.selectParentByUrl();
      });
    }
  }, {
    key: 'loadChildCategories',
    value: function loadChildCategories(parent) {
      var self = this;

      this.loadDataFromApi(parent).success(function (data) {
        var cats = JSON.parse(data);

        var html = '<option value="placeholder">Bitte wählen Sie</option>';
        html += self.renderOptions(cats);

        $('.gh-filter .cat2').html(html).val('placeholder').change();

        self.selectChildByUrl();
      });
    }
  }, {
    key: 'loadDataFromApi',
    value: function loadDataFromApi(parent) {
      return $.get('/api/zustaendigkeiten/' + parent);
    }
  }, {
    key: 'renderOptions',
    value: function renderOptions(data) {
      var html = void 0;

      data.forEach(function (el, index) {
        html += '<option value="' + el.id + '" data-group="' + el.group + '">' + el.name + '</option>';
      });

      return html;
    }
  }, {
    key: 'selectParentByUrl',
    value: function selectParentByUrl() {
      if (location.search.indexOf('group') === -1) {
        return false;
      }

      var group = _Toolbox2.default.getParameterByName('group');
      var value = _Toolbox2.default.getParameterByName('value');
      var parent = _Toolbox2.default.getParameterByName('parent');

      $('.gh-filter .cat1').val(parent).change();
    }
  }, {
    key: 'selectChildByUrl',
    value: function selectChildByUrl() {
      if (location.search.indexOf('group') === -1) {
        return false;
      }

      if (this.byUrlSelected) {
        return false;
      }

      var group = _Toolbox2.default.getParameterByName('group');
      var value = _Toolbox2.default.getParameterByName('value');
      var parent = _Toolbox2.default.getParameterByName('parent');

      $('.gh-filter .cat2').val(value).change();

      this.byUrlSelected = true;
    }
  }]);

  return AnsprechpartnerFilterPlugin;
}(_BasePlugin3.default);

exports.default = AnsprechpartnerFilterPlugin;

},{"../lib/Toolbox":3,"./BasePlugin":8}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Base class for Grasenhiller Plugins
 */
var BasePlugin = function () {
  function BasePlugin() {
    _classCallCheck(this, BasePlugin);
  }

  _createClass(BasePlugin, [{
    key: "shouldRun",
    value: function shouldRun() {
      return true;
    }
  }, {
    key: "run",
    value: function run() {
      if (this.shouldRun()) {
        this.init();
        this.addEventListeners();
      }
    }
  }, {
    key: "addEventListeners",
    value: function addEventListeners() {}
  }, {
    key: "init",
    value: function init() {}
  }]);

  return BasePlugin;
}();

exports.default = BasePlugin;

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BlogeintragPlugin = function (_BasePlugin) {
  _inherits(BlogeintragPlugin, _BasePlugin);

  function BlogeintragPlugin() {
    _classCallCheck(this, BlogeintragPlugin);

    return _possibleConstructorReturn(this, (BlogeintragPlugin.__proto__ || Object.getPrototypeOf(BlogeintragPlugin)).apply(this, arguments));
  }

  _createClass(BlogeintragPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-blogartikel');
    }
  }, {
    key: 'init',
    value: function init() {
      $('.field-name-ds-datum-kategorie').appendTo('#page-title').wrap('<div class="sub"></div>');

      var $position = $('.field-name-field-ansprechpartner .field-name-ds-titel-position .position');
      if ($position.text().trim() == "") {
        $position.remove();
      }
    }
  }]);

  return BlogeintragPlugin;
}(_BasePlugin3.default);

exports.default = BlogeintragPlugin;

},{"./BasePlugin":8}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FooterPlugin = function (_BasePlugin) {
  _inherits(FooterPlugin, _BasePlugin);

  function FooterPlugin() {
    _classCallCheck(this, FooterPlugin);

    return _possibleConstructorReturn(this, (FooterPlugin.__proto__ || Object.getPrototypeOf(FooterPlugin)).apply(this, arguments));
  }

  _createClass(FooterPlugin, [{
    key: 'init',
    value: function init() {
      var $footerTeaserBlock = $('#block-bean-bean-footer-teaser-block');

      var url = $footerTeaserBlock.find('.field-name-field-link-teaser-2 a').attr('href');

      $footerTeaserBlock.find('.group-teaser-2').wrap('<a href="' + url + '"></a>');
    }
  }]);

  return FooterPlugin;
}(_BasePlugin3.default);

exports.default = FooterPlugin;

},{"./BasePlugin":8}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require("./BasePlugin");

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _Toolbox = require("../lib/Toolbox");

var _Toolbox2 = _interopRequireDefault(_Toolbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FrontpagePlugin = function (_BasePlugin) {
  _inherits(FrontpagePlugin, _BasePlugin);

  function FrontpagePlugin() {
    _classCallCheck(this, FrontpagePlugin);

    return _possibleConstructorReturn(this, (FrontpagePlugin.__proto__ || Object.getPrototypeOf(FrontpagePlugin)).apply(this, arguments));
  }

  _createClass(FrontpagePlugin, [{
    key: "shouldRun",
    value: function shouldRun() {
      return $('body').hasClass('front');
    }
  }, {
    key: "init",
    value: function init() {
      this.fullWidthBoxes();
      this.linkKompetenzBox();
      this.angeboteMoreButton();
    }
  }, {
    key: "fullWidthBoxes",
    value: function fullWidthBoxes() {
      var $kompetenzenBox = $('#block-views-kompetenzen-block-1');
      _Toolbox2.default.syncHeight($kompetenzenBox.find('> .content'), $kompetenzenBox, function () {
        var documentWidth = $(window).outerWidth();
        return documentWidth > 752;
      });

      var $angeboteBox = $('#block-views-angebote-block-1');
      _Toolbox2.default.syncHeight($angeboteBox.find('.view-content'), $angeboteBox, function () {
        return true;
      }, function (height) {
        return height + $angeboteBox.find('.view-header').outerHeight();
      });
    }
  }, {
    key: "linkKompetenzBox",
    value: function linkKompetenzBox() {
      $('.view-kompetenzen .views-row').each(function () {
        var url = $(this).find('.views-field-nid a').attr('href');

        if (url) {
          $(this).find('.blue-wrapper').wrap("<a href=\"" + url + "\"></a>");
        }
      });
    }
  }, {
    key: "angeboteMoreButton",
    value: function angeboteMoreButton() {
      $('.view-angebote .more-link').appendTo('.view-angebote .view-content');
    }
  }]);

  return FrontpagePlugin;
}(_BasePlugin3.default);

exports.default = FrontpagePlugin;

},{"../lib/Toolbox":3,"./BasePlugin":8}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var KompetenzEmptyFieldPlugin = function (_BasePlugin) {
  _inherits(KompetenzEmptyFieldPlugin, _BasePlugin);

  function KompetenzEmptyFieldPlugin() {
    _classCallCheck(this, KompetenzEmptyFieldPlugin);

    return _possibleConstructorReturn(this, (KompetenzEmptyFieldPlugin.__proto__ || Object.getPrototypeOf(KompetenzEmptyFieldPlugin)).apply(this, arguments));
  }

  _createClass(KompetenzEmptyFieldPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-kompetenz');
    }
  }, {
    key: 'init',
    value: function init() {
      var $field = $('.field-name-field-zahlen-fakten-eintraege');
      var text = $field.text().trim();

      if (text == "") {
        $field.remove();
      }
    }
  }]);

  return KompetenzEmptyFieldPlugin;
}(_BasePlugin3.default);

exports.default = KompetenzEmptyFieldPlugin;

},{"./BasePlugin":8}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var KompetenzFloatingActionButtonPlugin = function (_BasePlugin) {
  _inherits(KompetenzFloatingActionButtonPlugin, _BasePlugin);

  function KompetenzFloatingActionButtonPlugin() {
    _classCallCheck(this, KompetenzFloatingActionButtonPlugin);

    return _possibleConstructorReturn(this, (KompetenzFloatingActionButtonPlugin.__proto__ || Object.getPrototypeOf(KompetenzFloatingActionButtonPlugin)).apply(this, arguments));
  }

  _createClass(KompetenzFloatingActionButtonPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-kompetenz') || $('body').hasClass('node-type-seite');
    }
  }, {
    key: 'init',
    value: function init() {
      var $popup = $('.page-floating').find('.field-name-field-ansprechpartner');

      if ($popup.length == 0) {
        $('.page-floating').remove();
      }

      $popup.prepend('<i class="fa fa-close"></i>');

      if ($(window).outerWidth() > 500) {
        $popup.addClass('visible');
      }
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      $('.fab-toggle').click(this.toggleFab);
      $('.page-floating').find('.field-name-field-ansprechpartner i').click(this.closeFab);
    }
  }, {
    key: 'toggleFab',
    value: function toggleFab(event) {
      event.preventDefault();
      $('.page-floating').find('.field-name-field-ansprechpartner').toggleClass('visible');
    }
  }, {
    key: 'closeFab',
    value: function closeFab(event) {
      event.preventDefault();
      $('.page-floating').find('.field-name-field-ansprechpartner').removeClass('visible');
    }
  }]);

  return KompetenzFloatingActionButtonPlugin;
}(_BasePlugin3.default);

exports.default = KompetenzFloatingActionButtonPlugin;

},{"./BasePlugin":8}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _Toolbox = require('../lib/Toolbox');

var _Toolbox2 = _interopRequireDefault(_Toolbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var KompetenzFloatingActionButtonPlugin = function (_BasePlugin) {
  _inherits(KompetenzFloatingActionButtonPlugin, _BasePlugin);

  function KompetenzFloatingActionButtonPlugin() {
    _classCallCheck(this, KompetenzFloatingActionButtonPlugin);

    return _possibleConstructorReturn(this, (KompetenzFloatingActionButtonPlugin.__proto__ || Object.getPrototypeOf(KompetenzFloatingActionButtonPlugin)).apply(this, arguments));
  }

  _createClass(KompetenzFloatingActionButtonPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-kompetenz');
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      $('.view-kompetenz-eintr-ge .field-name-custom-node-kompetenz-eintrag-im a, .view-kompetenz-eintr-ge .field-name-custom-node-kompetenz-eintrag-ti a').click(this.onClick);
    }
  }, {
    key: 'onClick',
    value: function onClick(event) {
      event.preventDefault();
      var targetId = $(this).attr('href');
      var target = $(targetId);
      _Toolbox2.default.slideTo(target);
    }
  }]);

  return KompetenzFloatingActionButtonPlugin;
}(_BasePlugin3.default);

exports.default = KompetenzFloatingActionButtonPlugin;

},{"../lib/Toolbox":3,"./BasePlugin":8}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var KompetenzViewPlugin = function (_BasePlugin) {
  _inherits(KompetenzViewPlugin, _BasePlugin);

  function KompetenzViewPlugin() {
    _classCallCheck(this, KompetenzViewPlugin);

    return _possibleConstructorReturn(this, (KompetenzViewPlugin.__proto__ || Object.getPrototypeOf(KompetenzViewPlugin)).apply(this, arguments));
  }

  _createClass(KompetenzViewPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('page-kompetenzen');
    }
  }, {
    key: 'init',
    value: function init() {
      $('.view-kompetenzen .views-row').each(function () {
        var url = $(this).find('.field-name-node-link a').attr('href');
        $(this).find('.img-title-wrapper').wrapInner('<a href="' + url + '"></a>');
      });
    }
  }]);

  return KompetenzViewPlugin;
}(_BasePlugin3.default);

exports.default = KompetenzViewPlugin;

},{"./BasePlugin":8}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MailchimpSignupBlockPlugin = function (_BasePlugin) {
  _inherits(MailchimpSignupBlockPlugin, _BasePlugin);

  function MailchimpSignupBlockPlugin() {
    _classCallCheck(this, MailchimpSignupBlockPlugin);

    return _possibleConstructorReturn(this, (MailchimpSignupBlockPlugin.__proto__ || Object.getPrototypeOf(MailchimpSignupBlockPlugin)).apply(this, arguments));
  }

  _createClass(MailchimpSignupBlockPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('.block-mailchimp-signup').length > 0;
    }
  }, {
    key: 'init',
    value: function init() {
      var self = this;

      $('.block-mailchimp-signup').each(function () {
        var $block = $(this);

        self.labelsToPlaceholders($block);

        $block.find('.form-actions').appendTo($block.find('.mailchimp-newsletter-mergefields'));
      });
    }
  }, {
    key: 'labelsToPlaceholders',
    value: function labelsToPlaceholders($block) {
      $block.find('.form-item').each(function () {
        var $label = $(this).find('label');
        var labelText = $label.text();
        $(this).find('input').attr('placeholder', labelText);
        $label.hide();
      });
    }
  }]);

  return MailchimpSignupBlockPlugin;
}(_BasePlugin3.default);

exports.default = MailchimpSignupBlockPlugin;

},{"./BasePlugin":8}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require("./BasePlugin");

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _FichtnerMerkzettel = require("../lib/FichtnerMerkzettel");

var _FichtnerMerkzettel2 = _interopRequireDefault(_FichtnerMerkzettel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MerkzettelPlugin = function (_BasePlugin) {
  _inherits(MerkzettelPlugin, _BasePlugin);

  function MerkzettelPlugin() {
    _classCallCheck(this, MerkzettelPlugin);

    return _possibleConstructorReturn(this, (MerkzettelPlugin.__proto__ || Object.getPrototypeOf(MerkzettelPlugin)).apply(this, arguments));
  }

  _createClass(MerkzettelPlugin, [{
    key: "shouldRun",
    value: function shouldRun() {
      return true;
    }
  }, {
    key: "init",
    value: function init() {
      var mz = new _FichtnerMerkzettel2.default();
      mz.init();

      $('.merkzettel--add').click(function (e) {
        e.preventDefault();

        var $row = $(this).parents('.node');

        $row.find('.merkzettel--add-region').show();
        $(this).hide();
      });

      $('.merkzettel--add-confirm').click(function (e) {
        if (mz.supported) {
          e.preventDefault();

          var $row = $(this).parents('.node');
          var $button = $row.find('.merkzettel--add');

          var item = {};
          item.id = $button.data('merkzettel-id');
          item.title = $button.data('merkzettel-title');
          item.typ = jQuery('input[name="mz-' + item.id + '"]:checked').val();
          item.menge = jQuery('#mz-menge-' + item.id).val();
          item.artikelnummer = $button.data('merkzettel-artikelnr');
          item.herstellernummer = $button.data('merkzettel-herstellernr');
          item.eannummer = $button.data('merkzettel-ean');

          mz.addItem(item);
        }
      });

      $('.merkzettel--typ-wrapper input[type="radio"][value="information"]').change(function (e) {
        var $row = $(this).parents('.node');
        $row.find('.merkzettel--menge-wrapper').hide();
      });

      $('.merkzettel--typ-wrapper input[type="radio"][value="angebot"]').change(function (e) {
        var $row = $(this).parents('.node');
        $row.find('.merkzettel--menge-wrapper').show();
      });
    }
  }]);

  return MerkzettelPlugin;
}(_BasePlugin3.default);

exports.default = MerkzettelPlugin;

},{"../lib/FichtnerMerkzettel":1,"./BasePlugin":8}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ParagraphsPlugin = function (_BasePlugin) {
  _inherits(ParagraphsPlugin, _BasePlugin);

  function ParagraphsPlugin() {
    _classCallCheck(this, ParagraphsPlugin);

    return _possibleConstructorReturn(this, (ParagraphsPlugin.__proto__ || Object.getPrototypeOf(ParagraphsPlugin)).apply(this, arguments));
  }

  _createClass(ParagraphsPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('.entity-paragraphs-item').length > 0;
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      this.addEventListenersTeaser();
      this.addEventListenersOeffnungszeiten();
    }
  }, {
    key: 'init',
    value: function init() {
      this.initTeaser();
      this.initOeffnungszeiten();
    }

    // Teaser Paragraph

  }, {
    key: 'initTeaser',
    value: function initTeaser() {
      $('.paragraphs-item-teaser').each(function () {
        var url = $(this).find('.field-name-custom-teaser-linked-title a').attr('href');

        if (url) {
          $(this).find('.field-name-field-bild img').wrap('<a href="' + url + '"></a>');
        }
      });
    }
  }, {
    key: 'addEventListenersTeaser',
    value: function addEventListenersTeaser() {}

    // Oeffnungszeiten Paragraph

  }, {
    key: 'initOeffnungszeiten',
    value: function initOeffnungszeiten() {
      // Add "Uhr"
      $('.oh-display-times').each(function (index, el) {
        var text = $(this).text();
        var textArray = text.split(', ');

        var newTextArray = [];
        textArray.forEach(function (text) {
          newTextArray.push(text + ' Uhr');
        });

        text = newTextArray.join(', ');
        $(this).html(text + '<br>');
      });
    }
  }, {
    key: 'addEventListenersOeffnungszeiten',
    value: function addEventListenersOeffnungszeiten() {}
  }]);

  return ParagraphsPlugin;
}(_BasePlugin3.default);

exports.default = ParagraphsPlugin;

},{"./BasePlugin":8}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ProduktuebersichtPlugin = function (_BasePlugin) {
  _inherits(ProduktuebersichtPlugin, _BasePlugin);

  function ProduktuebersichtPlugin() {
    _classCallCheck(this, ProduktuebersichtPlugin);

    return _possibleConstructorReturn(this, (ProduktuebersichtPlugin.__proto__ || Object.getPrototypeOf(ProduktuebersichtPlugin)).apply(this, arguments));
  }

  _createClass(ProduktuebersichtPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('page-produktuebersicht');
    }
  }, {
    key: 'init',
    value: function init() {
      this.initFilterToggle();
    }
  }, {
    key: 'initFilterToggle',
    value: function initFilterToggle() {
      $('.page-sidebar-left').click(function () {
        var viewportWidth = $(window).width();

        if (viewportWidth < 768) {
          if ($(this).find('.block').first().is(':visible')) {
            $(this).find('.block').slideUp();
          } else {
            $(this).find('.block').slideDown();
          }
        }
      });
    }
  }]);

  return ProduktuebersichtPlugin;
}(_BasePlugin3.default);

exports.default = ProduktuebersichtPlugin;

},{"./BasePlugin":8}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PushyPlugin = function (_BasePlugin) {
  _inherits(PushyPlugin, _BasePlugin);

  function PushyPlugin() {
    _classCallCheck(this, PushyPlugin);

    return _possibleConstructorReturn(this, (PushyPlugin.__proto__ || Object.getPrototypeOf(PushyPlugin)).call(this));
  }

  _createClass(PushyPlugin, [{
    key: 'init',
    value: function init() {
      // - Pushy sublevel navigation
      var pushy = $('.pushy');
      var trigger = pushy.find('li.expanded');
      var menuViews = pushy.find('ul > li > .view');

      if (menuViews.length > 0) {
        menuViews.each(function () {
          var menu = $(this).find('ul').first(),
              parent = $(this).closest('li.expanded');

          if (menu.length > 0 && parent.length > 0) {
            menu.addClass('menu');
            menu.appendTo(parent);
            $(this).parent().parent().remove();
          }
        });
      }

      trigger.click(function () {
        $(this).toggleClass('open');
        $(this).find('> ul').slideFadeToggle('fast');
      });
    }
  }]);

  return PushyPlugin;
}(_BasePlugin3.default);

exports.default = PushyPlugin;

},{"./BasePlugin":8}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 *
 */
var ReferenzPlugin = function (_BasePlugin) {
  _inherits(ReferenzPlugin, _BasePlugin);

  function ReferenzPlugin() {
    _classCallCheck(this, ReferenzPlugin);

    return _possibleConstructorReturn(this, (ReferenzPlugin.__proto__ || Object.getPrototypeOf(ReferenzPlugin)).apply(this, arguments));
  }

  _createClass(ReferenzPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-referenz');
    }
  }, {
    key: 'init',
    value: function init() {
      this.removeEmptySidebarFields();
    }
  }, {
    key: 'removeEmptySidebarFields',
    value: function removeEmptySidebarFields() {
      // Linked Logo
      var $linkedLogo = $('.region-sidebar .field-name-custom-linked-logo');
      if ($linkedLogo.find('a').html().trim() == "") {
        $linkedLogo.remove();
      }

      // Kundenmeinung
      if ($('.field-name-field-kundenmeinung-name').length == 0) {
        $('.field-name-custom-referenz-label-kunde').remove();
      }
    }
  }]);

  return ReferenzPlugin;
}(_BasePlugin3.default);

exports.default = ReferenzPlugin;

},{"./BasePlugin":8}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ShareButtonPlugin = function (_BasePlugin) {
  _inherits(ShareButtonPlugin, _BasePlugin);

  function ShareButtonPlugin() {
    _classCallCheck(this, ShareButtonPlugin);

    return _possibleConstructorReturn(this, (ShareButtonPlugin.__proto__ || Object.getPrototypeOf(ShareButtonPlugin)).apply(this, arguments));
  }

  _createClass(ShareButtonPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('.share-button.facebook').length > 0;
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      $('.share-button.facebook').click(this.openFbPopup);
    }
  }, {
    key: 'openFbPopup',
    value: function openFbPopup() {
      var url = $(this).data('url');

      var winWidth = 520;
      var winHeight = 350;

      var winTop = screen.height / 2 - winHeight / 2;
      var winLeft = screen.width / 2 - winWidth / 2;
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    }
  }]);

  return ShareButtonPlugin;
}(_BasePlugin3.default);

exports.default = ShareButtonPlugin;

},{"./BasePlugin":8}],23:[function(require,module,exports){
'use strict';

Drupal.behaviors.ghPluginsTables = {
  attach: function attach(context, settings) {
    var $ = jQuery,
        content = $('.content');

    content.find('table').each(function () {
      $(this).closest('.paragraphs-item-text').addClass('no-columns');

      // - tablehead top
      if ($(this).find('thead').length > 0) {
        $(this).addClass('has-thead-top');
      }

      // - tablehead left
      if ($(this).find('tbody > tr > th').length > 0) {
        $(this).addClass('has-thead-left');
      }

      // - no tablehead
      if (!$(this).hasClass('has-thead-top') && !$(this).hasClass('has-thead-left')) {
        $(this).addClass('has-no-thead');
      }

      // - two tableheads
      if ($(this).hasClass('has-thead-top') && $(this).hasClass('has-thead-left')) {
        $(this).addClass('has-two-theads');
      }

      // - collspan && rowspan
      if ($(this).find('td[colspan], td[rowspan], th[colspan], th[rowspan]').length > 0) {
        $(this).addClass('is-table-scroll');
        $(this).wrap('<div class="table-scroll-wrapper"></div>');
      } else {
        if (!$(this).hasClass('comprehension-table')) {
          $(this).addClass('is-table-box');
          $(this).wrap('<div class="table-box-wrapper"></div>');
        } else {
          $(this).addClass('is-table-scroll');
          $(this).wrap('<div class="table-scroll-wrapper"></div>');
        }
      }
    });

    // - comprehension table
    $('.comprehension-table').each(function () {
      var highlight = parseInt($(this).find('tr:first-of-type td:first-of-type').text());
      if (highlight) {
        $(this).find('tr').each(function () {
          $(this).find('td:nth-child(' + highlight + ')').addClass('comprehension-best-choice');
          $(this).find('td:nth-child(' + (highlight - 1) + ')').addClass('comprehension-best-choice-border');
        });
        $(this).find('tr:first-of-type td:first-of-type').text('');
        $(this).addClass('has-highlight');
      }

      $(this).find('td').each(function () {
        var text = $(this).text(),
            lineBreak = '<br>';

        if (text.length <= 1) {
          lineBreak = '';
        }

        if (text.charAt(0) == '+') {
          $(this).html('<i class="fa fa-check"></i>' + lineBreak + text.substring(1, text.length));
        }

        if (text.charAt(0) == '-') {
          $(this).html('<i class="fa fa-times"></i>' + lineBreak + text.substring(1, text.length));
        }
      });

      var secondRFirstT = $(this).find('tr:nth-of-type(2) td:first-of-type').html();

      if (secondRFirstT == '&nbsp;' || secondRFirstT.text().length) {
        $(this).addClass('first-empty');
      }
    });

    // - init check responsive
    $('.is-table-scroll', context).once('gh-plugins-tables-check-table-scroll', function () {
      Drupal.behaviors.ghPluginsTables.checkTableScroll($(this));
    });

    $('.is-table-box', context).once('gh-plugins-tables-check-table-box', function () {
      Drupal.behaviors.ghPluginsTables.checkTableBox($(this));
    });
  },

  checkTableScroll: function checkTableScroll(table) {
    var $ = jQuery,
        wrapperWidth = $('.table-scroll-wrapper').innerWidth();

    if (table.outerWidth() > wrapperWidth && !table.hasClass('is-resp-table')) {
      table.addClass('is-resp-table');
      table.attr('data-min-width', table.outerWidth());
      table.css('min-width', table.outerWidth());
    } else {
      if (table.hasClass('is-resp-table')) {
        table.css('min-width', table.attr('data-min-width') * 1.75);
      }

      if (wrapperWidth > table.attr('data-min-width')) {
        table.removeClass('is-resp-table');
        table.removeAttr('data-min-width');
        table.removeAttr('style');
      }
    }
  },

  checkTableBox: function checkTableBox(table) {
    var $ = jQuery,
        wrapperWidth = $('.table-box-wrapper').innerWidth(),
        columnCount = table.find('tr').first().find('td, th').length;

    if (columnCount >= 2) {
      if (table.outerWidth() > wrapperWidth && !$(this).hasClass('is-resp-table')) {
        table.addClass('is-resp-table');
        table.attr('data-breakpoint', table.outerWidth());
      } else if (table.outerWidth() > table.attr('data-breakpoint')) {
        table.removeClass('is-resp-table');
        table.removeAttr('data-breakpoint');
      }

      if (!table.find('td').first().attr('data-th')) {
        var firstRow = table.find('tr').first();

        table.find('tr').each(function () {
          $(this).find('td, th').each(function (i, el) {
            var newI = i + 1;
            $(this).attr('data-th', firstRow.children(':nth-child(' + newI + ')').text());
          });
        });
      }
    }
  }
};

jQuery(window).resize(function () {
  var $ = jQuery;

  $('.is-table-scroll').each(function () {
    Drupal.behaviors.ghPluginsTables.checkTableScroll($(this));
  });

  $('.is-table-box').each(function () {
    Drupal.behaviors.ghPluginsTables.checkTableBox($(this));
  });
});

},{}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TerminAnmeldungButtonPlugin = function (_BasePlugin) {
  _inherits(TerminAnmeldungButtonPlugin, _BasePlugin);

  function TerminAnmeldungButtonPlugin() {
    _classCallCheck(this, TerminAnmeldungButtonPlugin);

    return _possibleConstructorReturn(this, (TerminAnmeldungButtonPlugin.__proto__ || Object.getPrototypeOf(TerminAnmeldungButtonPlugin)).apply(this, arguments));
  }

  _createClass(TerminAnmeldungButtonPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-termin');
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      $('a.termin-call-to-action').click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass('disabled')) {
          var target = $('.field-name-custom-node-termin-anmeldeformul');

          $('html, body').animate({
            scrollTop: target.offset().top
          });
        }
      });
    }
  }]);

  return TerminAnmeldungButtonPlugin;
}(_BasePlugin3.default);

exports.default = TerminAnmeldungButtonPlugin;

},{"./BasePlugin":8}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BasePlugin2 = require('./BasePlugin');

var _BasePlugin3 = _interopRequireDefault(_BasePlugin2);

var _Toolbox = require('../lib/Toolbox');

var _Toolbox2 = _interopRequireDefault(_Toolbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TerminAnmeldungSelectBoxPlugin = function (_BasePlugin) {
  _inherits(TerminAnmeldungSelectBoxPlugin, _BasePlugin);

  function TerminAnmeldungSelectBoxPlugin() {
    _classCallCheck(this, TerminAnmeldungSelectBoxPlugin);

    var _this = _possibleConstructorReturn(this, (TerminAnmeldungSelectBoxPlugin.__proto__ || Object.getPrototypeOf(TerminAnmeldungSelectBoxPlugin)).call(this));

    _this.termine = [];
    return _this;
  }

  _createClass(TerminAnmeldungSelectBoxPlugin, [{
    key: 'shouldRun',
    value: function shouldRun() {
      return $('body').hasClass('node-type-termin');
    }
  }, {
    key: 'init',
    value: function init() {
      var self = this;

      $('#block-ds-extras-ds-termin-sidebar-blocks').find('.field-name-field-daten ul li > .date-display-range .date-display-end, .field-name-field-daten ul li .date-display-single').each(function () {
        var date = $(this).text();

        if (moment(date, 'DD.MM.YYYY - HH:mm').isAfter(moment())) {
          if ($(this).hasClass('date-display-end')) {
            date = $(this).parents('.date-display-range').text();
          }

          self.termine.push(date);
        } else {
          if ($(this).hasClass('date-display-end')) {
            $(this).parents('.date-display-range').addClass('crossed-out');
          } else {
            $(this).addClass('crossed-out');
          }
        }
      });

      // this.termine.forEach(function(termin, index) {
      //   if (!moment(termin, 'DD.MM.YYYY - HH:mm').isAfter(moment())) {
      //     self.termine.splice(index, 1);
      //   }
      // });

      if (this.termine.length > 1) {
        this.removeExistingField();
        this.addSelectField();
      } else {
        $('.webform-component--datum input[name="submitted[datum]"').val(this.termine[0]);
        this.hideExistingField();
      }
    }
  }, {
    key: 'addEventListeners',
    value: function addEventListeners() {
      _Toolbox2.default.selectFieldPlaceholderClass($('select[name="submitted[datum]"]'));
    }
  }, {
    key: 'removeExistingField',
    value: function removeExistingField() {
      $('.webform-component--datum').remove();
    }
  }, {
    key: 'hideExistingField',
    value: function hideExistingField() {
      $('.webform-component--datum').hide();
    }
  }, {
    key: 'dateIsInFuture',
    value: function dateIsInFuture(date) {
      console.log(date);
      if (date >= new Date()) {
        return true;
      }
    }
  }, {
    key: 'addSelectField',
    value: function addSelectField() {
      var newField = '\n      <div class="form-item webform-component webform-component-textfield webform-component--datum">\n        <label  for="edit-submitted-datum">\n          Datum <span class="form-required" title="Diese Angabe wird ben\xF6tigt.">*</span>\n        </label>\n        <select required="required"\n          id="edit-submitted-datum"\n          name="submitted[datum]"\n          class="form-select required">\n            <option value="">Bitte w\xE4hlen Sie einen Termin</option>';

      this.termine.forEach(function (element) {
        newField += '<option value="' + element + '">' + element + '</option>';
      });

      newField += '</select>\n      </div>\n    ';

      $('.webform-component--anzahl-der-teilnehmer').after(newField);
    }
  }]);

  return TerminAnmeldungSelectBoxPlugin;
}(_BasePlugin3.default);

exports.default = TerminAnmeldungSelectBoxPlugin;

},{"../lib/Toolbox":3,"./BasePlugin":8}]},{},[4])(4)
});

