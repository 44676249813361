Drupal.behaviors.ghPluginsTables = {
  attach: function (context, settings) {
    var $ = jQuery,
      content = $('.content');

    content.find('table').each(function(){
      $(this).closest('.paragraphs-item-text').addClass('no-columns');

      // - tablehead top
      if($(this).find('thead').length > 0) {
        $(this).addClass('has-thead-top');
      }

      // - tablehead left
      if($(this).find('tbody > tr > th').length > 0) {
        $(this).addClass('has-thead-left');
      }

      // - no tablehead
      if(!$(this).hasClass('has-thead-top') && !$(this).hasClass('has-thead-left')) {
        $(this).addClass('has-no-thead');
      }

      // - two tableheads
      if($(this).hasClass('has-thead-top') && $(this).hasClass('has-thead-left')) {
        $(this).addClass('has-two-theads');
      }

      // - collspan && rowspan
      if($(this).find('td[colspan], td[rowspan], th[colspan], th[rowspan]').length > 0) {
        $(this).addClass('is-table-scroll');
        $(this).wrap('<div class="table-scroll-wrapper"></div>')
      } else {
        if(!$(this).hasClass('comprehension-table')) {
          $(this).addClass('is-table-box');
          $(this).wrap('<div class="table-box-wrapper"></div>')
        } else {
          $(this).addClass('is-table-scroll');
          $(this).wrap('<div class="table-scroll-wrapper"></div>')
        }
      }
    });

    // - comprehension table
    $('.comprehension-table').each(function() {
      var highlight = parseInt($(this).find('tr:first-of-type td:first-of-type').text());
      if( highlight ) {
        $(this).find('tr').each(function() {
          $(this).find('td:nth-child(' + highlight + ')').addClass('comprehension-best-choice');
          $(this).find('td:nth-child(' + (highlight-1) + ')').addClass('comprehension-best-choice-border');
        });
        $(this).find('tr:first-of-type td:first-of-type').text('');
        $(this).addClass('has-highlight')
      }

      $(this).find('td').each(function() {
        var text = $(this).text(),
          lineBreak = '<br>';

        if(text.length <= 1) {
          lineBreak = '';
        }

        if(text.charAt(0) == '+') {
          $(this).html('<i class="fa fa-check"></i>' + lineBreak + text.substring(1, text.length));
        }

        if(text.charAt(0) == '-') {
          $(this).html('<i class="fa fa-times"></i>' + lineBreak + text.substring(1, text.length));
        }
      });

      var secondRFirstT = $(this).find('tr:nth-of-type(2) td:first-of-type').html();

      if(secondRFirstT == '&nbsp;' || secondRFirstT.text().length) {
        $(this).addClass('first-empty');
      }
    });

    // - init check responsive
    $('.is-table-scroll', context).once('gh-plugins-tables-check-table-scroll', function() {
      Drupal.behaviors.ghPluginsTables.checkTableScroll($(this));
    });

    $('.is-table-box', context).once('gh-plugins-tables-check-table-box', function() {
      Drupal.behaviors.ghPluginsTables.checkTableBox($(this));
    });
  },

  checkTableScroll:function(table) {
    var $ = jQuery,
      wrapperWidth = $('.table-scroll-wrapper').innerWidth();

    if(table.outerWidth() > wrapperWidth && !table.hasClass('is-resp-table')) {
      table.addClass('is-resp-table');
      table.attr('data-min-width', table.outerWidth());
      table.css('min-width', table.outerWidth());
    } else {
      if(table.hasClass('is-resp-table')) {
        table.css('min-width', table.attr('data-min-width')*1.75);
      }

      if(wrapperWidth > table.attr('data-min-width')) {
        table.removeClass('is-resp-table');
        table.removeAttr('data-min-width');
        table.removeAttr('style');
      }
    }
  },

  checkTableBox:function(table) {
    var $ = jQuery,
      wrapperWidth = $('.table-box-wrapper').innerWidth(),
      columnCount = table.find('tr').first().find('td, th').length;


    if(columnCount >= 2) {
      if(table.outerWidth() > wrapperWidth && !$(this).hasClass('is-resp-table')) {
        table.addClass('is-resp-table');
        table.attr('data-breakpoint', table.outerWidth());
      } else if(table.outerWidth() > table.attr('data-breakpoint')) {
        table.removeClass('is-resp-table');
        table.removeAttr('data-breakpoint');
      }

      if(!table.find('td').first().attr('data-th')) {
        var firstRow = table.find('tr').first();

        table.find('tr').each(function() {
          $(this).find('td, th').each(function(i, el) {
            var newI = i + 1;
            $(this).attr('data-th', firstRow.children(':nth-child(' + newI + ')').text());
          });
        });
      }
    }
  }
}

jQuery(window).resize(function() {
  var $ = jQuery;

  $('.is-table-scroll').each(function() {
    Drupal.behaviors.ghPluginsTables.checkTableScroll($(this));
  });

  $('.is-table-box').each(function() {
    Drupal.behaviors.ghPluginsTables.checkTableBox($(this));
  });
});

