import BasePlugin from './BasePlugin';
import Toolbox from '../lib/Toolbox';

export default class TerminAnmeldungSelectBoxPlugin extends BasePlugin {
  constructor() {
    super();
    this.termine = [];
  }

  shouldRun() {
    return $('body').hasClass('node-type-termin');
  }

  init() {
    let self = this;

    $('#block-ds-extras-ds-termin-sidebar-blocks').find('.field-name-field-daten ul li > .date-display-range .date-display-end, .field-name-field-daten ul li .date-display-single').each(function(){
      var date = $(this).text();

      if (moment(date, 'DD.MM.YYYY - HH:mm').isAfter(moment())) {
        if ($(this).hasClass('date-display-end')) {
          date = $(this).parents('.date-display-range').text();
        }

        self.termine.push(date);
      } else {
        if ($(this).hasClass('date-display-end')) {
          $(this).parents('.date-display-range').addClass('crossed-out');
        } else {
          $(this).addClass('crossed-out');
        }
      }
    });

    // this.termine.forEach(function(termin, index) {
    //   if (!moment(termin, 'DD.MM.YYYY - HH:mm').isAfter(moment())) {
    //     self.termine.splice(index, 1);
    //   }
    // });

    if (this.termine.length > 1) {
      this.removeExistingField();
      this.addSelectField();
    } else {
      $('.webform-component--datum input[name="submitted[datum]"').val(this.termine[0]);
      this.hideExistingField();
    }
  }

  addEventListeners() {
    Toolbox.selectFieldPlaceholderClass($('select[name="submitted[datum]"]'));
  }

  removeExistingField() {
    $('.webform-component--datum').remove();
  }

  hideExistingField() {
    $('.webform-component--datum').hide();
  }

  dateIsInFuture(date) {
    console.log(date);
    if (date >= new Date()) {
      return true;
    }
  }

  addSelectField() {
    let newField = `
      <div class="form-item webform-component webform-component-textfield webform-component--datum">
        <label  for="edit-submitted-datum">
          Datum <span class="form-required" title="Diese Angabe wird benötigt.">*</span>
        </label>
        <select required="required"
          id="edit-submitted-datum"
          name="submitted[datum]"
          class="form-select required">
            <option value="">Bitte wählen Sie einen Termin</option>`;

    this.termine.forEach(function(element) {
      newField += `<option value="${element}">${element}</option>`
    });

    newField += `</select>
      </div>
    `;

    $('.webform-component--anzahl-der-teilnehmer').after(newField);
  }
}
