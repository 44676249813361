import BasePlugin from './BasePlugin';
import Toolbox from '../lib/Toolbox';

export default class KompetenzFloatingActionButtonPlugin extends BasePlugin {

  shouldRun() {
    return $('body').hasClass('node-type-kompetenz');
  }


  addEventListeners() {
    $('.view-kompetenz-eintr-ge .field-name-custom-node-kompetenz-eintrag-im a, .view-kompetenz-eintr-ge .field-name-custom-node-kompetenz-eintrag-ti a').click(this.onClick);
  }

  onClick(event) {
    event.preventDefault();
    let targetId = $(this).attr('href');
    let target = $(targetId);
    Toolbox.slideTo(target);
  }
}
