import BasePlugin from './BasePlugin';

export default class BlogeintragPlugin extends BasePlugin {

    shouldRun() {
        return $('body').hasClass('node-type-blogartikel');
    }

    init() {
      $('.field-name-ds-datum-kategorie').appendTo('#page-title').wrap('<div class="sub"></div>');

      let $position = $('.field-name-field-ansprechpartner .field-name-ds-titel-position .position');
      if ($position.text().trim() == "") {
        $position.remove();
      }
    }
}
