import BasePlugin from './BasePlugin';
import Toolbox from "../lib/Toolbox";

export default class AnsprechpartnerFilterPlugin extends BasePlugin {

  shouldRun() {
    return $('body').hasClass('page-unternehmen-ansprechpartner');
  }

  init() {
    this.byUrlSelected = false;

    this.createFilterMarkup();
    this.insertParentCategories();
  }

  addEventListeners() {
    let self = this;

    $('.gh-filter .cat1').change(function () {
      let value = $(this).val();

      if (value === 'placeholder') {
        $('.gh-filter .cat2').html('<option class="placeholder" value="placeholder">Bitte wählen Sie</option>').val('placeholder').change().attr('disabled', true);

        if (location.search.indexOf('group') !== -1) {
          location.href = location.pathname;
        }
      } else {
        $('.gh-filter .cat2').attr('disabled', false);
        self.loadChildCategories(value);
      }
    });

    $('.gh-filter .cat2').change(function () {
      let value = $(this).val();
      let parent = $('.gh-filter .cat1').val();
      let group = $(this).find('option:selected').data('group');

      if (value !== "placeholder") {
        if (location.search.indexOf('group') === -1 || (location.search.indexOf('group') !== -1 && self.byUrlSelected)) {
          location.href = location.pathname + `?group=${group}&parent=${parent}&value=${value}`;
        }
      }
    });
  }

  createFilterMarkup() {
    $('.view-filters .views-exposed-widgets').prepend(`
            <div class="gh-filter-widget">
                <label>Finden Sie den passenden Ansprechpartner für</label>
                
                <div class="gh-filter">
                    <select class="cat1">
                        <option class="placeholder" value="placeholder">Laden...</option>
                    </select>
                    <select class="cat2" disabled>
                        <option class="placeholder" value="placeholder">Bitte wählen Sie</option>
                    </select>
                </div>
            </div>
        `);

    // Toolbox.selectFieldPlaceholderClass($('.gh-filter .cat1'), 'placeholder');
    // Toolbox.selectFieldPlaceholderClass($('.gh-filter .cat2'), 'placeholder');
  }

  insertParentCategories() {
    let self = this;

    this.loadDataFromApi(0).success(function (data) {
      let cats = JSON.parse(data);

      let html = '<option value="placeholder">Bitte wählen Sie</option>';
      html += self.renderOptions(cats);

      $('.gh-filter .cat1').html(html);

      self.selectParentByUrl();
    });
  }

  loadChildCategories(parent) {
    let self = this;

    this.loadDataFromApi(parent).success(function (data) {
      let cats = JSON.parse(data);

      let html = '<option value="placeholder">Bitte wählen Sie</option>';
      html += self.renderOptions(cats);

      $('.gh-filter .cat2').html(html).val('placeholder').change();

      self.selectChildByUrl();
    });
  }

  loadDataFromApi(parent) {
    return $.get('/api/zustaendigkeiten/' + parent);
  }

  renderOptions(data) {
    let html;

    data.forEach(function (el, index) {
      html += `<option value="${el.id}" data-group="${el.group}">${el.name}</option>`;
    });

    return html;
  }

  selectParentByUrl() {
    if (location.search.indexOf('group') === -1) {
      return false;
    }

    let group = Toolbox.getParameterByName('group');
    let value = Toolbox.getParameterByName('value');
    let parent = Toolbox.getParameterByName('parent');

    $('.gh-filter .cat1').val(parent).change();
  }

  selectChildByUrl() {
    if (location.search.indexOf('group') === -1) {
      return false;
    }

    if (this.byUrlSelected) {
      return false;
    }

    let group = Toolbox.getParameterByName('group');
    let value = Toolbox.getParameterByName('value');
    let parent = Toolbox.getParameterByName('parent');

    $('.gh-filter .cat2').val(value).change();

    this.byUrlSelected = true;
  }
}
