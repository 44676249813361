import BasePlugin from "./BasePlugin";

export default class ProduktuebersichtPlugin extends BasePlugin {

  shouldRun() {
    return $('body').hasClass('page-produktuebersicht');
  }

  init() {
    this.initFilterToggle();
  }

  initFilterToggle() {
    $('.page-sidebar-left').click(function() {
      let viewportWidth = $(window).width();

      if (viewportWidth < 768) {
        if ($(this).find('.block').first().is(':visible')) {
          $(this).find('.block').slideUp();
        } else {
          $(this).find('.block').slideDown();
        }
      }
    });
  }
}
