import BasePlugin from './BasePlugin';

export default class KompetenzEmptyFieldPlugin extends BasePlugin {

  shouldRun() {
    return $('body').hasClass('node-type-kompetenz');
  }

  init() {
    let $field = $('.field-name-field-zahlen-fakten-eintraege');
    let text = $field.text().trim();

    if (text == "") {
      $field.remove();
    }
  }
}
