import BasePlugin from './BasePlugin';

export default class TerminAnmeldungButtonPlugin extends BasePlugin {

  shouldRun() {
      return $('body').hasClass('node-type-termin');
  }


  addEventListeners() {
      $('a.termin-call-to-action').click(function(e) {
        e.preventDefault();

        if (!$(this).hasClass('disabled')) {
          let target = $('.field-name-custom-node-termin-anmeldeformul');

          $('html, body').animate({
            scrollTop: target.offset().top
          });
        }


      });
  }
}
