import BasePlugin from './BasePlugin';

export default class ShareButtonPlugin extends BasePlugin {

  shouldRun() {
    return $('.share-button.facebook').length > 0;
  }


  addEventListeners() {
    $('.share-button.facebook').click(this.openFbPopup);
  }

  openFbPopup() {
    let url = $(this).data('url');

    let winWidth = 520;
    let winHeight = 350;

    let winTop = (screen.height / 2) - (winHeight / 2);
    let winLeft = (screen.width / 2) - (winWidth / 2);
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
  }
}
