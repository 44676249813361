import BasePlugin from './BasePlugin';

export default class PushyPlugin extends BasePlugin {
  constructor() {
    super();
  }
  init() {
    // - Pushy sublevel navigation
    var pushy = $('.pushy');
    var trigger = pushy.find('li.expanded');
    var menuViews = pushy.find('ul > li > .view');

    if(menuViews.length > 0) {
      menuViews.each(function() {
        var menu = $(this).find('ul').first(),
          parent = $(this).closest('li.expanded');

        if(menu.length > 0 && parent.length > 0) {
          menu.addClass('menu');
          menu.appendTo(parent);
          $(this).parent().parent().remove();
        }
      });
    }

    trigger.click(function() {
      $(this).toggleClass('open');
      $(this).find('> ul').slideFadeToggle('fast');
    });
  }
}
