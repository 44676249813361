/**
 * Base class for Grasenhiller Plugins
 */
export default class BasePlugin {
  constructor() { }

  shouldRun() {
    return true;
  }

  run() {
    if (this.shouldRun()) {
      this.init();
      this.addEventListeners();
    }
  }

  addEventListeners() { }

  init() { }
}
