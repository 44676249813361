import BasePlugin from "./BasePlugin";
import FichtnerMerkzettel from "../lib/FichtnerMerkzettel";

export default class MerkzettelPlugin extends BasePlugin {
  shouldRun() {
    return true;
  }

  init() {
    let mz = new FichtnerMerkzettel();
    mz.init();

    $('.merkzettel--add').click(function (e) {
      e.preventDefault();

      let $row = $(this).parents('.node');

      $row.find('.merkzettel--add-region').show();
      $(this).hide();

    });

    $('.merkzettel--add-confirm').click(function (e) {
      if (mz.supported) {
        e.preventDefault();

        let $row = $(this).parents('.node');
        let $button = $row.find('.merkzettel--add');

        let item = {};
        item.id = $button.data('merkzettel-id');
        item.title =  $button.data('merkzettel-title');
        item.typ = jQuery('input[name="mz-' + item.id + '"]:checked').val();
        item.menge = jQuery('#mz-menge-' + item.id).val();
        item.artikelnummer = $button.data('merkzettel-artikelnr');
        item.herstellernummer = $button.data('merkzettel-herstellernr');
        item.eannummer = $button.data('merkzettel-ean');

        mz.addItem(item);
      }
    });

    $('.merkzettel--typ-wrapper input[type="radio"][value="information"]').change(function(e) {
      let $row = $(this).parents('.node');
      $row.find('.merkzettel--menge-wrapper').hide();
    });

    $('.merkzettel--typ-wrapper input[type="radio"][value="angebot"]').change(function(e) {
      let $row = $(this).parents('.node');
      $row.find('.merkzettel--menge-wrapper').show();
    });
  }
}
