import BasePlugin from './BasePlugin';
import Toolbox from '../lib/Toolbox';

export default class AngebotsUebersichtsPlugin extends BasePlugin {

    shouldRun() {
        return $('body').hasClass('page-angebote');
    }

    init() {
        let $angeboteBox = $('.view-angebote');
        Toolbox.syncHeight($angeboteBox.find('> .view-content'), $angeboteBox, function() {
            return true;
        });
    }
}
