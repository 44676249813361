import BasePlugin from "./BasePlugin";
export default class MailchimpSignupBlockPlugin extends BasePlugin {

  shouldRun() {
    return $('.block-mailchimp-signup').length > 0;
  }

  init() {
    let self = this;

    $('.block-mailchimp-signup').each(function() {
      let $block = $(this);

      self.labelsToPlaceholders($block);

      $block.find('.form-actions').appendTo($block.find('.mailchimp-newsletter-mergefields'));
    })
  }

  labelsToPlaceholders($block) {
    $block.find('.form-item').each(function() {
      let $label = $(this).find('label');
      let labelText = $label.text();
      $(this).find('input').attr('placeholder', labelText);
      $label.hide();
    });
  }
}
