import Merkzettel from './Merkzettel';

export default class FichtnerMerkzettel extends Merkzettel {
  constructor() {
    super();

    this.settings.buttonLabel = 'Zum Anfrageformular';
    this.typeLabels = {
      information: "Information",
      angebot: "Angebot"
    };
  }


  getItemHTML(item, index) {
    let anzahl = "";

    if (item.typ == 'angebot') {
      anzahl = ` (Menge: ${item.menge})`;
    }

    return `<li data-index="${index}">
      <i class="fa fa-close"></i>
      <div class="title">${item.title}</div>
      <span class="sub">${this.typeLabels[item.typ]}${anzahl}</span>      
    </li>`;
  }

  getItemSummary(item) {
    let nummern = [];

    if (item.artikelnummer != "") {
      nummern.push(item.artikelnummer)
    }

    if (item.herstellernummer != "") {
      nummern.push(item.herstellernummer)
    }

    if (item.eannummer != "") {
      nummern.push(item.eannummer)
    }

    nummern = nummern.join(' / ');
    nummern = ` [${nummern}]`;

    let anzahl = "";

    if (item.typ == 'angebot') {
      anzahl = ` [Menge: ${item.menge}]`;
    }

    return `- [${this.typeLabels[item.typ]}]${nummern} ${item.title}${anzahl}` + "\n";
  }
}
