export default class Toolbox {
  /**
   * Automatically adds a class to the specified element, if no value is selected
   * @param {jQuery} selectField - The selectfield where the placeholder class should be added to.
   * @param {string} [emptyValue=] - The value which should be treated as empty
   * @param {string} [placeholderClass=placeholder] - The class which will be added
   */
  static selectFieldPlaceholderClass(selectField, emptyValue = '', placeholderClass = 'placeholder') {
    let doCheck = function() {
      if (selectField.val() == emptyValue) {
        selectField.addClass(placeholderClass);
      } else {
        selectField.removeClass(placeholderClass);
      }
    };

    selectField.change(doCheck);
    doCheck();
  }

  /**
   * Animates a scroll to a specific element
   * @param {jQuery} target - Where to scroll to
   */
  static slideTo(target) {
    $('html, body').animate({
      scrollTop: target.offset().top
    });
  }

  /***
   * Returns a query parameter
   * @param {string} name - The key of the GET Param
   * @param {string} [url=window.location.href] - The url from which the param shouuld be taken from
   * @returns {string}
   */
  static getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); // This is just to avoid case sensitiveness
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();// This is just to avoid case sensitiveness for query parameter name
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static syncHeight($source, $target, shouldRun = function () { return true; }, manipulateHeight = function(height){return height;}) {

    let resize = function() {
      if (shouldRun()) {
        let height = $source.outerHeight();
        $target.height(manipulateHeight(height));
      } else {
        $target.height('auto');
      }
    };

    $(window).resize(resize);

    window.setTimeout(resize, 500);
    resize();
  }
}
