import BasePlugin from './BasePlugin';

export default class FooterPlugin extends BasePlugin {

  init() {
    let $footerTeaserBlock = $('#block-bean-bean-footer-teaser-block');

    let url = $footerTeaserBlock.find('.field-name-field-link-teaser-2 a').attr('href');

    $footerTeaserBlock.find('.group-teaser-2').wrap(`<a href="${url}"></a>`);
  }
}
